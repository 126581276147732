import React from 'react'
import PropTypes from 'prop-types'

import { create } from 'jss'
import JssProvider from 'react-jss/lib/JssProvider'
import {
  createGenerateClassName,
  jssPreset,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'
import {
  themes,
  Provider as ThemeProviderDS
} from '@naturacosmeticos/natds-web'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider as FVThemeProvider } from '@naturacosmeticos/natds-react'
import { useFVTheme } from './hooks/useFVTheme'
import GlobalStyles from './styles/ds-styles/global-styles'
import { COMPANY_ID } from './models/Company'

import { isDesignSystemToggleActive } from './config'

import 'src/styles/fonts.styl'
import 'src/styles/base.styl'

const styleNode = document.createComment('insertion-point-jss')
document.head.insertBefore(styleNode, document.head.firstChild)

const generateClassName = createGenerateClassName()
const jss = create(jssPreset())
jss.options.insertionPoint = 'insertion-point-jss'

const theme = createMuiTheme({
  fontFamily: 'Roboto, sansserif',
  palette: {
    primary: {
      main: '#f3971f'
    },
    secondary: {
      main: '#f04f23'
    }
  }
})

const themeByCompanyId = (companyId) => {
  const company = companyId ?? COMPANY_ID.NATURA // NATURA AS DEFAULT
  switch (company) {
    case COMPANY_ID.AVON:
      return themes.avon.light
    case COMPANY_ID.NATURA:
      return themes.natura.light
    default:
      return themes.natura.light
  }
}

export default function Theme({ children, companyId }) {
  const currentTheme = themeByCompanyId(companyId)
  const fvTheme = useFVTheme(companyId)
  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      {isDesignSystemToggleActive() ? (
        <ThemeProviderDS theme={currentTheme}>
          <FVThemeProvider theme={fvTheme}>
            <StyledThemeProvider theme={currentTheme}>
              <GlobalStyles />
              {children}
            </StyledThemeProvider>
          </FVThemeProvider>
        </ThemeProviderDS>
      ) : (
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      )}
    </JssProvider>
  )
}

Theme.propTypes = {
  children: PropTypes.node,
  companyId: PropTypes.string
}
