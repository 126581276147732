
import { buildTheme } from '@naturacosmeticos/natds-react'
import { COMPANY_NAME_BY_ID } from '../models/Company'
import { useEffect, useState } from 'react'
import { ColorScheme } from '../models/theme-ds/color-scheme'

export const useFVTheme = (companyId) => {
  const company = COMPANY_NAME_BY_ID[companyId]?.toLowerCase()
  const defaultTheme = buildTheme(company, ColorScheme.LIGHT)
  const [fvTheme, setTheme] = useState(defaultTheme)

  useEffect(() => {
    const newTheme = buildTheme(company, ColorScheme.LIGHT)
    setTheme(newTheme)
  }, [company])

  return fvTheme
}