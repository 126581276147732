import {
  isChileWithLegoToggleActive,
  isEnableHostedUiToggleActive
} from 'src/config'
import { ROLES } from 'src/infra/Api/CHL/roles.chile'
import { ROUTES } from 'src/App.routes'

export default () => ({
  countryName: 'CHILE',
  preRegister: {
    useCreatePersonFromBFF: isEnableHostedUiToggleActive()
  },
  businessModel: {
    shouldHideButton: false
  },
  personalData: {
    hasFullNameInfoText: false,
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: false
  },
  completedStep: {
    rejected: {
      hasEmailContact: false
    },
    shouldSearchIndication: true,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  address: {
    gmap: {
      fields: [
        {
          label: 'region',
          parent: 'countryName'
        },
        {
          label: 'department',
          parent: 'region'
        },
        {
          label: 'province',
          parent: 'department'
        },
        {
          label: 'district',
          parent: 'province'
        }
      ]
    },
    levels: {
      COUNTRY: 0,
      REGION: 1,
      CITY: 2,
      NEIGHBORHOOD: 3,
      STREET: 4,
      NUMBER: 5,
      COMPLEMENT: 6,
      REFERENCES: 7
    },
    hasZipCode: false
  },
  authenticationLegacy: false,
  appStoreUrls: {
    android: 'https://play.google.com/store/apps/details?id=net.natura.cn',
    iOS: 'https://apps.apple.com/cl/app/mi-negocio-natura/id1197578002'
  },
  callEndpointComplete: true,
  contactValidationLegacy: false,
  date: {
    format: 'DD/MM/YYYY'
  },
  documents: {
    lengthValidation: {
      rut: {
        max: 10,
        min: 8
      }
    },
    mask: {
      rut: '9999999***'
    },
    personal: {
      nationalRegistry: 20
    }
  },
  featureLite: true,
  getBusinessModelFromApi: true,
  getFiltersFromApi: true,
  map: {
    center: {
      lat: -12.0552073,
      lng: -77.0627323
    }
  },
  ocr: {
    fields: ['identificationCardFront', 'identificationCardBack'],
    validations: [
      {
        attachmentsToValidate: [
          {
            field: 'identificationCardFront',
            type: 27
          },
          {
            field: 'identificationCardBack',
            type: 28
          }
        ],
        document: 'nationalRegistry',
        documentType: 20
      }
    ]
  },
  offlineMode: true,
  phone: {
    areaCodeCharCount: {
      line: 2,
      mobile: 3
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '56',
    customValidation: {
      invalid: /.*/,
      line: /.*/,
      mobile: {
        start_code: {
          number: 9,
          validation: /^9/
        },
        start_number_negation: {
          number: 0,
          validation: /^.{3}[1-9]/
        }
      }
    },
    emailRequired: false,
    lengthValidation: {
      line: {
        min: 8
      },
      mobile: {
        min: 9
      }
    },
    mask: {
      line: '9999 9999',
      mobile: '99999 9999'
    },
    placeholder: {
      line: 'XXXX XXXX',
      mobile: '9XXXX XXXX'
    }
  },
  policies: [
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 4,
      fieldId: 'personal_data',
      id: 9,
      name: 'Autorización de uso de datos personales',
      sequence: '77698fdc-719b-4d0b-ba15-5765b5432585',
      version: 1
    },
    {
      acceptanceRequired: false,
      businessModel: 1,
      country: 4,
      fieldId: 'invoice',
      id: 10,
      name: 'Aceptación recepción de factura digital',
      sequence: 'bbd6f1b2-5b22-45ee-b8c1-fdf7255f1ae0',
      version: 1
    }
  ],
  selectPaymentPlan: true,
  shouldCallCreatePaymentPlanInPaymentView: false,
  shouldCallGetPersonInVerifyContacts: false,
  shouldCallRegisterError: false,
  shouldUseBusinessModelOnRegister: true,
  showProfileEditButton: false,
  hasLego: isChileWithLegoToggleActive(),
  hasDigitalStore: false,
  shouldCallCompleteAddressStepUsingBff: isEnableHostedUiToggleActive(),
  shouldUpdatePersonFromBff: isEnableHostedUiToggleActive(),
  shouldUpdateRegistrationSubstatusFromBff: isEnableHostedUiToggleActive(),
  shouldGetPersonDetailsFromBff: isEnableHostedUiToggleActive(),
  shouldGetEstablishmentsFromBff: isEnableHostedUiToggleActive(),
  shouldGetBusinessModelFromBff: isEnableHostedUiToggleActive(),
  shouldGetRejectionReasonsFromBff: isEnableHostedUiToggleActive(),
  shouldGetAddressFromBff: isEnableHostedUiToggleActive(),
  shouldIgnorePeopleContextResponseOnLogin: false,
  shouldCompleteRegisterWithBff: isEnableHostedUiToggleActive(),
  isHostedUIEnabled: isEnableHostedUiToggleActive(),
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: false,
  redirectUrlToLogin: {
    url: `${process.env.DOMAIN_HOSTED_UI}`,
    clientId: `${process.env.CLIENT_ID_COGNITO}`
  },
  urlRecoverSession: {
    url: `${process.env.REGISTERS_URL}/recover-session`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  urlSignOut: {
    url: `${process.env.REGISTERS_URL}/sign-out`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: ROUTES.SELF_REGISTER_WITH_COUNTRY.replace(':country', 'cl'),
      external: false,
      shouldSSO: false
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false
    },
    CSC: {
      url: ROUTES.DASHBOARD,
      external: false,
      shouldSSO: false
    }
  },
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/Natura',
      label: 'Facebook Natura'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/NaturaPeruOficial',
      label: 'Youtube Natura'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/natura_peru',
      label: 'Twitter Natura'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/natura.peru/',
      label: 'Instagram Natura'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'http://bit.ly/2qqkqa9',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'http://apple.co/2qpA45g',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
})
