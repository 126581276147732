export const toggles = () => ({
  DEFAULT_FLOW_TOGGLE: process.env.DEFAULT_FLOW_TOGGLE,
  ARGENTINA_FLOW_TOGGLE: process.env.ARGENTINA_FLOW_TOGGLE,
  CHILE_FLOW_TOGGLE: process.env.CHILE_FLOW_TOGGLE,
  COLOMBIA_FLOW_TOGGLE: process.env.COLOMBIA_FLOW_TOGGLE,
  COLOMBIA_ADDRESS_MFE_TOGGLE: process.env.COLOMBIA_ADDRESS_MFE_TOGGLE,
  PERU_ADDRESS_MFE_TOGGLE: process.env.PERU_ADDRESS_MFE_TOGGLE,
  MALAYSIA_ADDRESS_MFE_TOGGLE: process.env.MALAYSIA_ADDRESS_MFE_TOGGLE,
  CHILE_ADDRESS_MFE_TOGGLE: process.env.CHILE_ADDRESS_MFE_TOGGLE,
  ENABLE_DIGITAL_STORE_STEP_FOR_CHILE_FLOW_TOGGLE:
    process.env.ENABLE_DIGITAL_STORE_STEP_FOR_CHILE_FLOW_TOGGLE,
  ENABLE_SAVE_DIGITAL_STORE_PENDING_SUBSTATUS_TOGGLE:
    process.env.ENABLE_SAVE_DIGITAL_STORE_PENDING_SUBSTATUS_TOGGLE,
  MALAYSIA_FLOW_TOGGLE: process.env.MALAYSIA_FLOW_TOGGLE,
  FEATURE_OFFLINE: process.env.FEATURE_OFFLINE,
  FEATURE_COL_LITE: process.env.FEATURE_COL_LITE,
  GET_SOURCE_SYSTEM_FROM_LOCAL_STORAGE:
    process.env.GET_SOURCE_SYSTEM_FROM_LOCAL_STORAGE,
  PERFORM_ALLOCATION_CALL_TOGGLE: process.env.PERFORM_ALLOCATION_CALL_TOGGLE,
  REMOVE_DOCUMENTS_STEP: process.env.REMOVE_DOCUMENTS_STEP,
  REMOVE_SMS_VALIDATION_TOGGLE: process.env.REMOVE_SMS_VALIDATION_TOGGLE,
  REMOVE_OCR_VALIDATION_TOGGLE: process.env.REMOVE_OCR_VALIDATION_TOGGLE,
  GET_PERSON_WHEN_LOGGED_USER_IS_FV:
    process.env.GET_PERSON_WHEN_LOGGED_USER_IS_FV,
  ENABLE_DIGITAL_STORE_STEP_FOR_PERU_FLOW_TOGGLE:
    process.env.ENABLE_DIGITAL_STORE_STEP_FOR_PERU_FLOW_TOGGLE,
  ENABLE_DIGITAL_STORE_STEP_FOR_COLOMBIA_FLOW_TOGGLE:
    process.env.ENABLE_DIGITAL_STORE_STEP_FOR_COLOMBIA_FLOW_TOGGLE,
  ENABLE_DIGITAL_STORE_STEP_FOR_AVON_MEXICO_FLOW_TOGGLE:
    process.env.ENABLE_DIGITAL_STORE_STEP_FOR_AVON_MEXICO_FLOW_TOGGLE,
  ENABLE_DIGITAL_STORE_STEP_FOR_AVON_BRAZIL_FLOW_TOGGLE:
    process.env.ENABLE_DIGITAL_STORE_STEP_FOR_AVON_BRAZIL_FLOW_TOGGLE,
  ENABLE_DIGITAL_STORE_STEP_FOR_AVON_ARGENTINA_FLOW_TOGGLE:
    process.env.ENABLE_DIGITAL_STORE_STEP_FOR_AVON_ARGENTINA_FLOW_TOGGLE,
  GET_COMMERCIAL_STRUCTURE_FROM_BRM_LIGHT:
    process.env.GET_COMMERCIAL_STRUCTURE_FROM_BRM_LIGHT,
  INDICATION_STEP_ON_MFE_TOGGLE: process.env.INDICATION_STEP_ON_MFE_TOGGLE,
  ENABLE_ALLOCATE_BFF_TOGGLE: process.env.ENABLE_ALLOCATE_BFF_TOGGLE,
  ARGENTINA_PERSONAL_DATA_MFE_TOGGLE:
    process.env.ARGENTINA_PERSONAL_DATA_MFE_TOGGLE,
  COLOMBIA_PERSONAL_DATA_MFE_TOGGLE:
    process.env.COLOMBIA_PERSONAL_DATA_MFE_TOGGLE,
  CHILE_PERSONAL_DATA_MFE_TOGGLE: process.env.CHILE_PERSONAL_DATA_MFE_TOGGLE,
  MALAYSIA_PERSONAL_DATA_MFE_TOGGLE:
    process.env.MALAYSIA_PERSONAL_DATA_MFE_TOGGLE,
  PERU_PERSONAL_DATA_MFE_TOGGLE: process.env.PERU_PERSONAL_DATA_MFE_TOGGLE,
  ENABLE_PERU_LAST_NAME_LOGIC_HANDLER_TOGGLE:
    process.env.ENABLE_PERU_LAST_NAME_LOGIC_HANDLER_TOGGLE,
  CHILE_WITH_LEGO_TOGGLE: process.env.CHILE_WITH_LEGO_TOGGLE,
  COLOMBIA_WITH_LEGO_TOGGLE: process.env.COLOMBIA_WITH_LEGO_TOGGLE,
  ENABLE_COMPLETED_STEP_ON_MFE_TOGGLE:
    process.env.ENABLE_COMPLETED_STEP_ON_MFE_TOGGLE,
  ENABLE_COMPLETED_STEP_ON_MFE_CHILE_CSC_TOGGLE:
    process.env.ENABLE_COMPLETED_STEP_ON_MFE_CHILE_CSC_TOGGLE,
  ENABLE_ATTACHMENTS_STEP_ON_MFE_TOGGLE:
    process.env.ENABLE_ATTACHMENTS_STEP_ON_MFE_TOGGLE,
  ENABLE_ATTACHMENTS_STEP_ON_MFE_CHILE_TOGGLE:
    process.env.ENABLE_ATTACHMENTS_STEP_ON_MFE_CHILE_TOGGLE,
  AVON_MEXICO_FLOW_TOGGLE: process.env.AVON_MEXICO_FLOW_TOGGLE,
  AVON_BRAZIL_FLOW_TOGGLE: process.env.AVON_BRAZIL_FLOW_TOGGLE,
  AVON_ARGENTINA_FLOW_TOGGLE: process.env.AVON_ARGENTINA_FLOW_TOGGLE,
  ENABLE_HOSTED_UI_TOGGLE: process.env.ENABLE_HOSTED_UI_TOGGLE,
  ENABLE_PERSON_OTHER_INFORMATION: process.env.ENABLE_PERSON_OTHER_INFORMATION,
  ENABLE_DESIGN_SYSTEM: process.env.ENABLE_DESIGN_SYSTEM,
  ENABLE_PERSON_MIDDLE_NAME: process.env.ENABLE_PERSON_MIDDLE_NAME,
  COUNTRY_SELECTION_BY_COMPANY_ID: process.env.COUNTRY_SELECTION_BY_COMPANY_ID,
  SHOULD_USE_ID_TOKEN: process.env.SHOULD_USE_ID_TOKEN,
  ENABLE_CHILE_DOCUMENTATION_ON_MFE_TOGGLE:
    process.env.ENABLE_CHILE_DOCUMENTATION_ON_MFE_TOGGLE,
  ENABLE_MALAYSIA_DOCUMENTATION_ON_MFE_TOGGLE:
    process.env.ENABLE_MALAYSIA_DOCUMENTATION_ON_MFE_TOGGLE,
  PRE_REGISTER_MFE_TOGGLE: process.env.PRE_REGISTER_MFE_TOGGLE,
  PRE_REGISTER_MFE_CHILE_TOGGLE: process.env.PRE_REGISTER_MFE_CHILE_TOGGLE,
  INDICATION_STATELESS_WITH_BYPASS_TOGGLE:
    process.env.INDICATION_STATELESS_WITH_BYPASS_TOGGLE,
  ADDRESS_PAGE_STATELESS_TOGGLE: process.env.ADDRESS_PAGE_STATELESS_TOGGLE,
  PERSONAL_DATA_SEARCH_ADDRESS_WITH_ZIP_CODE:
    process.env.PERSONAL_DATA_SEARCH_ADDRESS_WITH_ZIP_CODE,
  ARGENTINA_WITH_COGNITO_TOGGLE: process.env.ARGENTINA_WITH_COGNITO_TOGGLE,
  DISABLE_FIELDS_PERSONAL_DATA: process.env.DISABLE_FIELDS_PERSONAL_DATA,
  NATURA_MEXICO_FLOW_TOGGLE: process.env.NATURA_MEXICO_FLOW_TOGGLE
})

export const isToggleActive = (flag) => {
  const result = localStorage.getItem(flag)
  return toBoolean(result)
}

export const isTogglePresentOnLocalStorage = (flag) => {
  return (
    localStorage.getItem(flag) !== undefined &&
    localStorage.getItem(flag) !== null
  )
}

const getToggleValue = (toggleKey) => {
  if (isTogglePresentOnLocalStorage(toggleKey)) return isToggleActive(toggleKey)
  return toBoolean(toggles()[toggleKey])
}

export const isUseIdTokenActive = () => {
  return getToggleValue('SHOULD_USE_ID_TOKEN')
}

export const isSMSValidationRemovalToggleActive = () => {
  return getToggleValue('REMOVE_SMS_VALIDATION_TOGGLE')
}

export const isRemoveDocumentsStepToggleActive = () => {
  return getToggleValue('REMOVE_DOCUMENTS_STEP')
}

export const isAllocationCallToggleActive = () => {
  return getToggleValue('PERFORM_ALLOCATION_CALL_TOGGLE')
}

export const isDesignSystemToggleActive = () => {
  return getToggleValue('ENABLE_DESIGN_SYSTEM')
}

export const isRemoveOCRValidationToggleActive = () => {
  return getToggleValue('REMOVE_OCR_VALIDATION_TOGGLE')
}

export const isGetSourceSystemFromLocalStorageToggleActive = () => {
  return getToggleValue('GET_SOURCE_SYSTEM_FROM_LOCAL_STORAGE')
}

export const isGetPersonWhenLoggedUserIsFVToggleActive = () => {
  return getToggleValue('GET_PERSON_WHEN_LOGGED_USER_IS_FV')
}

export const isDefaultFlowToggleActive = () => {
  return getToggleValue('DEFAULT_FLOW_TOGGLE')
}

export const isArgentinaFlowToggleActive = () => {
  return getToggleValue('ARGENTINA_FLOW_TOGGLE')
}

export const isAvonBrazilFlowToggleActive = () => {
  return getToggleValue('AVON_BRAZIL_FLOW_TOGGLE')
}

export const isChileFlowToggleActive = () => {
  return getToggleValue('CHILE_FLOW_TOGGLE')
}

export const isColombiaFlowToggleActive = () => {
  return getToggleValue('COLOMBIA_FLOW_TOGGLE')
}

export const isEnableDigitalStoreStepForColombiaFlowToggleActive = () => {
  return getToggleValue('ENABLE_DIGITAL_STORE_STEP_FOR_COLOMBIA_FLOW_TOGGLE')
}

export const isEnableDigitalStoreStepForChileFlowToggleActive = () => {
  return getToggleValue('ENABLE_DIGITAL_STORE_STEP_FOR_CHILE_FLOW_TOGGLE')
}

export const isEnableDigitalStoreStepForPeruFlowToggleActive = () => {
  return getToggleValue('ENABLE_DIGITAL_STORE_STEP_FOR_PERU_FLOW_TOGGLE')
}

export const isEnableDigitalStoreStepForAvonMexicoFlowToggleActive = () => {
  return getToggleValue('ENABLE_DIGITAL_STORE_STEP_FOR_AVON_MEXICO_FLOW_TOGGLE')
}

export const isEnableDigitalStoreStepForAvonArgentinaFlowToggleActive = () => {
  return getToggleValue(
    'ENABLE_DIGITAL_STORE_STEP_FOR_AVON_ARGENTINA_FLOW_TOGGLE'
  )
}

export const isEnableDigitalStoreStepForAvonBrazilFlowToggleActive = () => {
  return getToggleValue('ENABLE_DIGITAL_STORE_STEP_FOR_AVON_BRAZIL_FLOW_TOGGLE')
}

export const isSaveDigitalStorePendingSubstatusToggleActive = () => {
  return getToggleValue('ENABLE_SAVE_DIGITAL_STORE_PENDING_SUBSTATUS_TOGGLE')
}

export const isGetCommercialStructureFromBRMLightActive = () => {
  return getToggleValue('GET_COMMERCIAL_STRUCTURE_FROM_BRM_LIGHT')
}

export const isMalaysiaFlowToggleActive = () => {
  return getToggleValue('MALAYSIA_FLOW_TOGGLE')
}

export const isColombiaAddressMfeToggleActive = () => {
  return getToggleValue('COLOMBIA_ADDRESS_MFE_TOGGLE')
}

export const isPeruAddressMfeToggleActive = () => {
  return getToggleValue('PERU_ADDRESS_MFE_TOGGLE')
}

export const isMalaysiaAddressMfeToggleActive = () => {
  return getToggleValue('MALAYSIA_ADDRESS_MFE_TOGGLE')
}

export const isChileAddressMfeToggleActive = () => {
  return getToggleValue('CHILE_ADDRESS_MFE_TOGGLE')
}

export const isIndicationStepOnMfe = () => {
  return getToggleValue('INDICATION_STEP_ON_MFE_TOGGLE')
}

export const isEnabledAllocateBff = () => {
  return getToggleValue('ENABLE_ALLOCATE_BFF_TOGGLE')
}

export const isArgentinaPersonalDataMfeToggleActive = () => {
  return getToggleValue('ARGENTINA_PERSONAL_DATA_MFE_TOGGLE')
}

export const isColombiaPersonalDataMfeToggleActive = () => {
  return getToggleValue('COLOMBIA_PERSONAL_DATA_MFE_TOGGLE')
}

export const isChilePersonalDataMfeToggleActive = () => {
  return getToggleValue('CHILE_PERSONAL_DATA_MFE_TOGGLE')
}

export const isMalaysiaPersonalDataMfeToggleActive = () => {
  return getToggleValue('MALAYSIA_PERSONAL_DATA_MFE_TOGGLE')
}

export const isPeruPersonalDataMfeToggleActive = () => {
  return getToggleValue('PERU_PERSONAL_DATA_MFE_TOGGLE')
}

export const isPeruLastNameLogicHandlerActive = () => {
  return getToggleValue('ENABLE_PERU_LAST_NAME_LOGIC_HANDLER_TOGGLE')
}

export const isChileWithLegoToggleActive = () => {
  return getToggleValue('CHILE_WITH_LEGO_TOGGLE')
}

export const isColombiaWithLegoToggleActive = () => {
  return getToggleValue('COLOMBIA_WITH_LEGO_TOGGLE')
}

export const isChileDocumentationToggleActive = () => {
  return getToggleValue('ENABLE_CHILE_DOCUMENTATION_ON_MFE_TOGGLE')
}

export const isMalaysiaDocumentationToggleActive = () => {
  return getToggleValue('ENABLE_MALAYSIA_DOCUMENTATION_ON_MFE_TOGGLE')
}

export const isEnableCompletedStepOnMfeToggleActive = () => {
  return getToggleValue('ENABLE_COMPLETED_STEP_ON_MFE_TOGGLE')
}

export const isEnableCompletedStepOnMfeChileCSCToggleActive = () => {
  return getToggleValue('ENABLE_COMPLETED_STEP_ON_MFE_CHILE_CSC_TOGGLE')
}

export const isEnableAttachmentsStepOnMfeToggleActive = () => {
  return getToggleValue('ENABLE_ATTACHMENTS_STEP_ON_MFE_TOGGLE')
}

export const isEnableAttachmentsStepOnMfeForChileToggleActive = () => {
  return getToggleValue('ENABLE_ATTACHMENTS_STEP_ON_MFE_CHILE_TOGGLE')
}

export const isAvonMexicoFlowToggleActive = () => {
  return getToggleValue('AVON_MEXICO_FLOW_TOGGLE')
}

export const isAvonArgentinaFlowToggleActive = () => {
  return getToggleValue('AVON_ARGENTINA_FLOW_TOGGLE')
}

export const isNaturaMexicoFlowToggleActive = () => {
  return getToggleValue('NATURA_MEXICO_FLOW_TOGGLE')
}

export const isEnableHostedUiToggleActive = () => {
  return getToggleValue('ENABLE_HOSTED_UI_TOGGLE')
}

export const isEnablePersonOtherInformation = () => {
  return getToggleValue('ENABLE_PERSON_OTHER_INFORMATION')
}

export const isEnablePersonMiddleName = () => {
  return getToggleValue('ENABLE_PERSON_MIDDLE_NAME')
}

export const isCountrySelectionByCompanyId = () => {
  return getToggleValue('COUNTRY_SELECTION_BY_COMPANY_ID')
}

export const isPreRegisterMfeToggleActive = () => {
  return getToggleValue('PRE_REGISTER_MFE_TOGGLE')
}

export const isPreRegisterMfeChileToggleActive = () => {
  return getToggleValue('PRE_REGISTER_MFE_CHILE_TOGGLE')
}

export const isIndicationStatelessWithBypassToggleActive = () => {
  return getToggleValue('INDICATION_STATELESS_WITH_BYPASS_TOGGLE')
}

export const isAddressPageStatelessToggleActive = () => {
  return getToggleValue('ADDRESS_PAGE_STATELESS_TOGGLE')
}

export const isPersonalDataSearchAddressWithZipCode = () => {
  return getToggleValue('PERSONAL_DATA_SEARCH_ADDRESS_WITH_ZIP_CODE')
}

export const isEnableAuthorizationCodeMessageAvonMX = () => {
  return getToggleValue('AUTHORIZATION_CODE_MESSAGE_AVON_MX')
}

export const isArgentinaCognitoToggleActive = () => {
  return getToggleValue('ARGENTINA_WITH_COGNITO_TOGGLE')
}

export const isDisableFieldsPersonalData = () => {
  return getToggleValue('DISABLE_FIELDS_PERSONAL_DATA')
}

const toBoolean = (value) => value === 'true' || value === true
