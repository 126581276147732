import { isEnableDigitalStoreStepForAvonArgentinaFlowToggleActive } from '../../../../../config'
import { ROLES } from 'src/infra/Api/AVON_ARG/roles.avon-argentina.js'
import { DOCUMENTS } from '../../../../../models/Document'

export default ()=> ({
  countryName: 'AVON ARGENTINA',
  documents: {
    lengthValidation: {
      dni: {
        max: 8,
        min: 7
      }
    },
    mask: {
      dni: '99999999'
    },
    personal: {
      dni: DOCUMENTS.DNI_ARGENTINA
    }
  },
  address: {
    levels: {
      COUNTRY: 0,
      PROVINCE: 1,
      CITY: 2,
      LOCATION: 3,
      NEIGHBORHOOD: 4,
      STREET: 5,
      NUMBER: 6,
      COMPLEMENT: 7,
      REFERENCE: 8,
      ZIPCODE: 9
    },
    hasZipCode: true
  },
  phone: {
    areaCodeCharCount: {
      line: 1,
      mobile: 3
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '54',
    customValidation: {
      invalid: /.*/,
      line: /^/,
      mobile: {
        start_code: {
          validation: /^/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 10
      },
      mobile: {
        min: 10
      }
    },
    mask: {
      line: '9999999999',
      mobile: '999999-9999'
    },
    placeholder: {
      line: 'XXXXXXXXXX',
      mobile: 'XXXXXX-XXXX'
    }
  },
  completedStep: {
    rejected: {
      hasEmailContact: true
    },
    shouldSearchIndication: true,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  preRegister: {
    useCreatePersonFromBFF: true
  },
  personalData: {
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: true
  },
  businessModel: {
    shouldHideButton: false
  },
  date: {
    format: 'DD/MM/YYYY'
  },
  hasDigitalStore: isEnableDigitalStoreStepForAvonArgentinaFlowToggleActive(),
  shouldUpdatePersonFromBff: true,
  shouldUpdateRegistrationSubstatusFromBff: true,
  shouldGetPersonDetailsFromBff: true,
  shouldGetEstablishmentsFromBff: true,
  shouldGetBusinessModelFromBff: true,
  shouldGetRejectionReasonsFromBff: true,
  shouldGetAddressFromBff: true,
  shouldIgnorePeopleContextResponseOnLogin: true,
  shouldCompleteRegisterWithBff: true,
  isHostedUIEnabled: true,
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: true,
  urlToReportsFV: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=arg&company=avon`,
  redirectUrlToLogin: {
    url: `${process.env.DOMAIN_HOSTED_UI_AVON}`,
    clientId: `${process.env.CLIENT_ID_COGNITO_AVON}`
  },
  urlRecoverSession: {
    url: `${process.env.REGISTERS_REPORTS_URL_AVON}/recover-session`,
    apiKey: `${process.env.REGISTERS_REPORTS_X_API_KEY_AVON}`
  },
  urlSignOut: {
    url: `${process.env.REGISTERS_REPORTS_URL_AVON}/sign-out`,
    apiKey: `${process.env.REGISTERS_REPORTS_X_API_KEY_AVON}`
  },
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=arg&company=avon`,
      external: true,
      shouldSSO: true
    },
    CNWithDigitalStore: {
      url: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=arg&company=avon`,
      external: true,
      shouldSSO: true
    },
    CSC: {
      url: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=arg&company=avon`,
      external: true,
      shouldSSO: true
    },
    GR: {
      url: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=arg&company=avon`,
      external: true,
      shouldSSO: true
    }
  },
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/AvonArgentina/',
      label: 'Facebook Avon'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/AvonArg',
      label: 'Youtube Avon'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/AvonArg',
      label: 'Twitter Avon'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/avonarg/',
      label: 'Instagram Avon'
    },
    {
      type: 'pinterest',
      link: 'https://www.avon.com.ar/',
      label: 'Pinterest Avon'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'https://play.google.com/store/apps/details?id=com.avoncf.app',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'https://apps.apple.com/ar/app/avon-shop/id1560397447',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
})
