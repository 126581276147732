import { isArgentinaCognitoToggleActive } from '../../../../../config'
import { ATTACHMENT_TYPES } from '../../../../AttachmentTypes'
import { DOCUMENTS } from '../../../../../models/Document'
import { ROLES } from 'src/infra/Api/ARG/roles.argentina'
import { ROUTES } from 'src/App.routes'

export default () => ({
  countryName: 'ARGENTINA',
  preRegister: {
    useCreatePersonFromBFF: isArgentinaCognitoToggleActive()
  },
  businessModel: {
    shouldHideButton: false
  },
  personalData: {
    hasFullNameInfoText: true,
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: false
  },
  completedStep: {
    rejected: {
      hasEmailContact: true
    },
    shouldSearchIndication: true,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  address: {
    gmap: {
      fields: [
        {
          label: 'level1',
          parent: 'countryName'
        },
        {
          label: 'level2',
          parent: 'level1'
        },
        {
          label: 'level3',
          parent: 'level2'
        },
        {
          label: 'level4',
          parent: 'level3'
        }
      ],
      isLegacy: true
    },
    levels: {
      COUNTRY: 0,
      LEVEL1: 1,
      LEVEL2: 2,
      LEVEL3: 3,
      LEVEL4: 4,
      STREET: 5,
      NUMBER: 6,
      COMPLEMENT: 7,
      REFERENCES: 8
    },
    hasZipCode: true
  },
  authenticationLegacy: false,
  appStoreUrls: {
    android:
      'https://play.google.com/store/apps/details?id=net.natura.semprepresente&referrer=utm_source%3Drede_natura_mobile%26utm_medium%3Dbotao_google_play',
    iOS:
      'https://itunes.apple.com/app/apple-store/id1061637276?pt=1379476&ct=rede_natura_mobile_botao_appstore&mt=8'
  },
  callEndpointComplete: false,
  contactValidationLegacy: false,
  date: {
    format: 'DD/MM/YYYY'
  },
  documents: {
    lengthValidation: {
      dni: {
        max: 8,
        min: 7
      }
    },
    mask: {
      dni: '99999999'
    },
    personal: {
      dni: DOCUMENTS.DNI_ARGENTINA
    }
  },
  featureLite: false,
  getBusinessModelFromApi: false,
  getFiltersFromApi: true,
  map: {
    center: {
      lat: 4.624335,
      lng: -74.063644
    }
  },
  ocr: {
    fields: ['identificationCardFront', 'identificationCardBack'],
    validations: [
      {
        attachmentsToValidate: [
          {
            field: 'identificationCardFront',
            type: ATTACHMENT_TYPES.DNI_ARGENTINA_FRONT
          },
          {
            field: 'identificationCardBack',
            type: ATTACHMENT_TYPES.DNI_ARGENTINA_BACK
          }
        ],
        document: 'dni',
        documentType: DOCUMENTS.DNI_ARGENTINA
      }
    ]
  },
  offlineMode: false,
  phone: {
    emailRequired: false,
    areaCodeCharCount: {
      line: 1,
      mobile: 3
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '54',
    customValidation: {
      invalid: /.*/,
      line: /^/,
      mobile: {
        start_code: {
          validation: /^/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 10
      },
      mobile: {
        min: 10
      }
    },
    mask: {
      line: '9999999999',
      mobile: '999999-9999'
    },
    placeholder: {
      line: 'XXXXXXXXXX',
      mobile: 'XXXXXX-XXXX'
    }
  },
  policies: [
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 5,
      fieldId: 'authorization',
      id: 3,
      name: 'Aceptación de las condiciones de Natura',
      sequence: '2544b0ad-e4b3-4ab1-b5c9-7894848e9d62',
      version: 1
    },
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 5,
      fieldId: 'personal_data',
      id: 4,
      name: 'Autorización de uso de datos personales',
      sequence: '6f2ecd27-bf00-446e-83ab-ceaf18062749',
      version: 1
    },
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 5,
      fieldId: 'conditions_privacy',
      id: 5,
      name: 'Autorización de la consulta del estado de crédito',
      sequence: '47387872-f2f6-4572-aa24-266bfa59b4a6',
      version: 1
    },
    {
      acceptanceRequired: false,
      businessModel: 1,
      country: 5,
      fieldId: 'invoice',
      id: 6,
      name: 'Aceptación recepción de factura digital',
      sequence: '5a278b2d-5825-4d32-805d-b9ffcb21c871',
      version: 1
    }
  ],
  selectPaymentPlan: true,
  shouldCallCreatePaymentPlanInPaymentView: true,
  shouldCallGetPersonInVerifyContacts: false,
  shouldCallRegisterError: false,
  shouldUseBusinessModelOnRegister: false,
  showProfileEditButton: false,
  hasLego: false,
  hasDigitalStore: false,
  shouldCallCompleteAddressStepUsingBff: isArgentinaCognitoToggleActive(),
  shouldUpdatePersonFromBff: isArgentinaCognitoToggleActive(),
  shouldUpdateRegistrationSubstatusFromBff: isArgentinaCognitoToggleActive(),
  shouldGetPersonDetailsFromBff: isArgentinaCognitoToggleActive(),
  shouldGetEstablishmentsFromBff: isArgentinaCognitoToggleActive(),
  shouldGetBusinessModelFromBff: isArgentinaCognitoToggleActive(),
  shouldGetRejectionReasonsFromBff: isArgentinaCognitoToggleActive(),
  shouldGetAddressFromBff: isArgentinaCognitoToggleActive(),
  shouldIgnorePeopleContextResponseOnLogin: false,
  shouldCompleteRegisterWithBff: isArgentinaCognitoToggleActive(),
  isHostedUIEnabled: isArgentinaCognitoToggleActive(),
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: false,
  redirectUrlToLogin: {
    url: `${process.env.DOMAIN_HOSTED_UI}`,
    clientId: `${process.env.CLIENT_ID_COGNITO}`
  },
  urlRecoverSession: {
    url: `${process.env.REGISTERS_URL}/recover-session`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  urlSignOut: {
    url: `${process.env.REGISTERS_URL}/sign-out`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: ROUTES.SELF_REGISTER_WITH_COUNTRY.replace(':country', 'ar'),
      external: false,
      shouldSSO: false
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false
    },
    CSC: {
      url: ROUTES.DASHBOARD,
      external: false,
      shouldSSO: false
    }
  },
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/Natura',
      label: 'Facebook Natura'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/NaturaPeruOficial',
      label: 'Youtube Natura'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/natura_peru',
      label: 'Twitter Natura'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/natura.peru/',
      label: 'Instagram Natura'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'http://bit.ly/2qqkqa9',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'http://apple.co/2qpA45g',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
})
